import React from 'react'
import './LandingPage.css'

const FAQs = () => {

    return(

        <div className="FAQ">

            <div className="FAQ-Header">FREQUENTLY ASKED QUESTIONS</div>

            <div>
                <div className="FAQ-q">HOW DO I MINT A SIMBULL TECHSTARS MENTOR 2022 NFT? </div>
                <div className="FAQ-a">These are only available for SimBull's Techstars Mentors. If you were one, you'll have received an email with a link to you're specific NFT and further directions on how to mint.</div>
            </div>

            <hr className="FAQ-hr" />

            <div>
                <div className="FAQ-q">HOW MUCH DOES IT COST?</div>
                <div className="FAQ-a">These are free, claimable NFTs. Additionally, SimBull covers all gas fees, so they are claimable even with a wallet you create right now with no funds.</div>
            </div>

            <hr className="FAQ-hr" />

            <div>
                <div className="FAQ-q">HOW MANY ARE THERE?</div>
                <div className="FAQ-a">There are a total of 70 NFTs, but it's possible that not all of them are claimed.</div>
            </div>

            <hr className="FAQ-hr" />

            <div>
                <div className="FAQ-q">CAN I DO ANYTHING WITH IT?</div>
                <div className="FAQ-a">Currently these are just commemorative, but it's possible they could become useful in the future.</div>
            </div>

            <hr className="FAQ-hr" />

            <div>
                <div className="FAQ-q">I'M HAVING SOME TROUBLES. CAN I GET HELP?</div>
                <div className="FAQ-a">Of course, please email customer-service@simbull.app and we'll be happy to help.</div>
            </div>

        </div>

    )
}

export default FAQs