import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChainId, ThirdwebProvider } from '@thirdweb-dev/react';

// This is the chainId your dApp will work on.
const activeChainId = ChainId.Mainnet;

ReactDOM.render(
  <React.StrictMode>
    <ThirdwebProvider
      sdkOptions={{
        gasless: {
          openzeppelin: {
            relayerUrl: 'https://api.defender.openzeppelin.com/autotasks/559d5174-3d35-4006-8a13-e0fb5bb97c4b/runs/webhook/121c0b48-cdff-4786-be88-aaddd11609e6/EjFiRiYsd1WQT3zmLqovp1',
          },
        },
      }}
      desiredChainId={ChainId.Mumbai}>
      <App />
    </ThirdwebProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
