import React from 'react'
import './LandingPage.css'
import Logo from '../../assets/Simbull_Logo.png'
import NFT from '../../assets/simbull_techstars_mentor_nft.gif'
import FAQs from './FAQs'
import Mentor from '../Claim/Mentor'

const LandingPage = () => {
    return(
        <div className="LandingPage">

            <div className="LandingPage-Logo-Div">
                <img src={Logo}  className="LandingPage-Logo" />
            </div>
            
            <div className="DesktopContainer">

                <div className="LandingPage-NFT-Div">
                    <div className="LandingPage-Header">SIMBULL TECHSTARS MENTOR 2022 NFT</div>
                    <img src={NFT} className="LandingPage-NFT" />
                    <div style={{fontSize: '1.2rem', fontWeight: '300'}}>THE FREE, GASLESS MINT IS NOW OPEN!</div>

                    <hr className="style6" />

                </div> 

                <div style={{textAlign: 'center', fontWeight: '400', padding: '3%', color: 'gray', fontStyle: 'italic'}}>

                    <div style={{marginBottom: '15px'}}>In SimBull's first ever NFT drop, it's giving back to those who gave first with a commemorative NFT collection for SimBull's Techstars' Mentors.</div>

                    <div style={{marginBottom: '15px'}}>The SimBull Techstars Mentor 2022 NFT Collection is a limited collection of 70 NFTs made up of 1 unique design, minted on the Polygon blockchain via thirdweb. They are free to claim and gas cost is covered by SimBull.</div>

                    <div style={{marginBottom: '15px'}}>These NFTs are currently only a collectible, and have no special utility. However, potential utility possibilies could include a guaranteed minimum allocation in SimBull's next funding round. </div>

                    <a href="https://testnets.opensea.io/collection/simbull-techstars-mentor-2022" target="_blank" ><button className='Collection-Btn'>View Collection on OpenSea</button></a>

                </div>

                <hr className="style6" />

                <FAQs landingPage={true} />

                <hr className="style6" />

            </div>

        </div>
    )
}

export default LandingPage