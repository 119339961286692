import React, {useEffect} from 'react'
import {ThirdwebProvider, useAddress, useDisconnect, useMetamask, useNetwork, useNFTDrop, useEditionDrop } from '@thirdweb-dev/react';
import LandingPage from './components/LandingPage/LandingPage'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Mentor from './components/Claim/Mentor'

function App() {
  const address = useAddress();
  const connectWithMetamask = useMetamask();
  const disconnectWallet = useDisconnect();

  useEffect(()=>{
    //console.log(address)
  },[address])

  const network = useNetwork()

  /*const edition = useNFTCollection('0xFa67bcA14E03C51863b4de42715334Fa896B6ada')
  console.log(edition)

  async function getNFTs() {
    const nfts = await edition.getAll()
    console.log(nfts)
    return nfts
  }

  useEffect(async ()=>{
    getNFTs()
    
  },[])*/



  //const nftDrop = useNFTDrop("0x3aa583f25aEa7b19c96317B962178C17c73d024C")
  //const nftDrop = useEditionDrop("0xeaEEB5213Faa5884bFfEbad74A932db9F7f541Cc")

  const contract = useEditionDrop("0xeaEEB5213Faa5884bFfEbad74A932db9F7f541Cc")
    //console.log(contract)
    //contract.get('10').then(res => console.log(res))
  

  const tokenId = 1;
  const quantity = 11;

  useEffect(()=>{
    //console.log(nftDrop)
    //nftDrop.balanceOf(address, tokenId).then(res => console.log(res))
    
  },[])

  const claimNFT = async () => {
    //const tokenId = 0; // the id of the NFT you want to claim
    //const quantity = 1; // how many NFTs you want to claim

    //const tx = await contract.claimTo(address, tokenId, quantity);
    //const receipt = tx.receipt; // the transaction receipt
    //const claimedTokenId = tx.id; // the id of the NFT claimed
    //const claimedNFT = await tx.data(); // (optional) get the claimed NFT metadata
    try {
      /*let claim = await nftDrop.claimTo(address, tokenId, quantity)
      console.log(claim)
      const receipt = claim.receipt; // the transaction receipt
      const claimedTokenId = claim.id; // the id of the NFT claimed
      const claimedNFT = await claim.data(); // (optional) get the claimed NFT metadata
      console.log(receipt)
      console.log(claimedTokenId)
      console.log(claimedNFT)*/
      console.log("🎉 NFT claimed successfully!");
    } catch (err) {
      console.log("💩 Error claiming NFT: ", err);
    }
  };
    

  return (
    <div>

      <ThirdwebProvider>
        <div className="App">



            <Router>

              <Routes>

                <Route path="/mentor/:id" element={<Mentor contract={contract} address={address} />} />
                <Route path="/" element={<LandingPage />} />
                
              </Routes>

            </Router>


          
        </div>
      </ThirdwebProvider>

      {/*address ? (
        <>
          <button onClick={disconnectWallet}>Disconnect Wallet</button>
          <p>Your address: {address}</p>
          <button onClick={claimNFT}>collect nft</button>
        </>
      ) : (
        <button onClick={connectWithMetamask}>Connect with Metamask</button>
      )*/}

    </div>
  );
}

export default App;
