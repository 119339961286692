import React, { useState, useEffect } from 'react'
import '../LandingPage/LandingPage.css'
import { useMetamask, useAddress, useEditionDrop, useDisconnect } from '@thirdweb-dev/react'
import Logo from '../../assets/Simbull_Logo.png'
import NFT from '../../assets/simbull_techstars_mentor_nft.gif'
import FAQs from '../LandingPage/FAQs'
import {useLocation} from 'react-router-dom'

const Mentor = (props) => {

    const [tokenID, setTokenID] = useState()
    const [name, setName] = useState()
    const [collected, setCollected] = useState(false)
    const [nft, setNft] = useState({})

    const address = useAddress()
    const connectWithMetamask = useMetamask();
    const disconnectWallet = useDisconnect();

    const location = useLocation();

    useEffect(()=>{
        console.log(collected)
    },[collected])

    useEffect(()=>{
        let tokenID1
        if(props.contract && !tokenID){
            if(location && location.pathname){
                tokenID1 = location.pathname.substring(location.pathname.length - 4, location.pathname.length-2)
                if(tokenID1[0] === '0'){
                    tokenID1 = tokenID1.substring(1,2)
                }
                setTokenID(tokenID1)
    
            }
            props.contract.get(tokenID1).then(res => {
                if(res && res.metadata && res.metadata.attributes && res.metadata.attributes.mentor){
                    setName(res.metadata.attributes.mentor)
                    setNft(res)
                }
                if(res && res.supply && res.supply._hex){
                    console.log(res.supply._hex)
                    let amount_claimed = res.supply._hex//.toSubstring(res.supply._hex.length - 1, res.supply._hex.length)
                    console.log(typeof amount_claimed)
                    if(amount_claimed !== '0x00'){
                        setCollected(true)
                        console.log(amount_claimed)
                    }
                    else setCollected(false)
                }
            }).catch(err => console.log(err))
        }
    },[props.contract])

    const claimNFT = () => {
        if(address && props.contract){

            let quantity = 1
            let token = parseInt(tokenID)

            props.contract.claimTo(address, token, quantity).then(res => {

            }).catch(err => console.log(err))

        }

    }

    return(
        <div className="LandingPage">

            <div className="LandingPage-Logo-Div">
                <img src={Logo}  className="LandingPage-Logo" />
                
            </div>
            
            

            <div className="LandingPage-NFT-Div">
                <div className="LandingPage-Header">SIMBULL TECHSTARS MENTOR 2022 NFT</div>
                
                <img src={NFT} className="LandingPage-NFT" />
                {name ? <div className="Claim-Name">{name}</div> : <div className="Claim-Name" style={{color: 'white'}}>-</div>}
                <div style={{fontSize: '1.2rem'}}>THE FREE, GASLESS MINT IS NOW OPEN!</div>
                <button className="Claim-Btn" style={{opacity: collected ? '.25' : address ? '1' : '.25' }}>{(address && collected) ? 'Claimed' : 'Claim Now'}</button>
                {address ? 
                    <div className="Claim-Address">{`Address: ${address.substring(0,4)}...${address.substring(address.length - 6, address.length)}`}</div> 
                    : 
                    <button className="Claim-login" onClick={connectWithMetamask}>Connect Wallet</button>
                }
                <hr className="style6" />

            </div>

            <div style={{textAlign: 'center', fontWeight: '400', padding: '3%', color: 'gray', fontStyle: 'italic'}}>

                <div style={{marginBottom: '15px'}}>In SimBull's first ever NFT drop, it's giving back to those who gave first with a commemorative NFT collection for SimBull's Techstars' Mentors.</div>

                <div style={{marginBottom: '15px'}}>The SimBull Techstars Mentor 2022 NFT Collection is a limited collection of 70 NFTs made up of 1 unique design, minted on the Polygon blockchain via thirdweb. They are free to claim and gas cost is covered by SimBull.</div>

                <div style={{marginBottom: '15px'}}>These NFTs are currently only a collectible, and have no special utility. However, potential utility possibilies could include a guaranteed minimum allocation in SimBull's next funding round. </div>

                <a href="https://testnets.opensea.io/collection/simbull-techstars-mentor-2022" target="_blank" ><button className='Collection-Btn'>View Collection on OpenSea</button></a>

            </div>

            <hr className="style6" />

            <FAQs />

            <hr className="style6" />

            {address && <button className="Claim-logout" onClick={disconnectWallet}>Disconnect Wallet</button>}

        </div>
    )
}

export default Mentor